import React, {ReactNode} from 'react';

export interface TouchableProps {
  children: ReactNode;
  onClick: () => void;
}

const Touchable = ({children, onClick, ...rest}: TouchableProps) => (
  <div {...rest} onClick={onClick}>
    {children}
  </div>
);

export default Touchable;
