import React, {Component} from 'react';
import {ReactNode} from 'react';
import {Provider} from './ResponsiveContext';
import {Breakpoint, getBreakpointsState} from './Breakpoint';

export interface ResponsiveProviderState {
  size: Breakpoint;
}

export interface ResponsiveProviderProps {
  children: ReactNode;
}

export class ResponsiveProvider extends Component<
  ResponsiveProviderProps,
  ResponsiveProviderState
> {
  processingFrame = 0;

  pendingResize = false;

  state = {size: Breakpoint.lg};

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);

    if (this.processingFrame) {
      window.cancelAnimationFrame(this.processingFrame);
    }
  }

  onResize = () => {
    if (this.processingFrame) {
      this.pendingResize = true;
      return;
    }

    this.processingFrame = window.requestAnimationFrame(this.updateState);
  };

  onResizeProcessed = () => {
    this.processingFrame = 0;

    // in case the window was resized during the last update, we wanna make sure to run it again.
    if (this.pendingResize) {
      this.pendingResize = false;
      this.onResize();
    }
  };

  updateState = () => {
    const size = getBreakpointsState(window.innerWidth);

    this.setState({size}, this.onResizeProcessed);
  };

  render() {
    const {children} = this.props;
    const {size} = this.state;

    return <Provider value={size}>{children}</Provider>;
  }
}
