import React from 'react';

export interface LinkProps {
  link: string;
  text: string;
}

export const Link = ({link, text, ...rest}: LinkProps) => (
  <a href={link} target="_blank" rel="noopener noreferrer" {...rest}>
    {text}
  </a>
);
