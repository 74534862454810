import styled from 'styled-components';
import Touchable from '../Touchable/Touchable';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';
import View from '../View/View';

export const RetakeTestTouchable = styled(Touchable)`
  background-color: ${ThemePrimaryColor};
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-top: 25px;
  cursor: pointer;

  @media only screen and (min-width: 600px) {
    margin-top: 0;
    margin-right: 25px;
    align-self: center;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const Wrapper = styled(View)`
  flex: 2;
  display: flex;
  justify-content: center;
`;

export const RetakeTestIcon = styled(View)`
  align-self: center;
`;

export const RetakeTest = styled(View)`
  line-height: 25px;
  margin-right: 5px;
  @media only screen and (min-width: 600px) {
    margin-right: 10px;
  }
`;
