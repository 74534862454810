import styled from 'styled-components';
import View from '../View/View';
import Text from '../Text/Text';

export const Result = styled(View)`
  flex: 8;
  padding: 25px;
`;

export const Classification = styled(Text)`
  font-family: 'Montserrat-SemiBold';
  font-size: 20px;
`;

export const Description = styled(View)`
  font-style: italic;
  margin-top: 10px;
`;

export const DescriptionText = styled(Text)`
  font-family: 'Montserrat-Italic';
`;

export const ResultWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  flex: 8;
`;
