import styled from 'styled-components';
import View from '../View/View';
import Text from '../Text/Text';
import {BlackColor} from '../ThemeProvider/ThemeProvider.shared';
import {Link} from '../Link/Link';

export const LeftSection = styled(View)`
  flex: 2;
  padding: 15px 0 15px 15px;
`;

export const CenterSection = styled(View)`
  flex: 2;
  padding: 15px 0;
`;

export const RightSection = styled(View)`
  flex: 1;
  padding: 15px 15px 15px 0;
`;

export const Title = styled(Text)`
  font-family: 'Montserrat-Italic';
`;

export const BlackLink = styled(Link)`
  color: ${BlackColor};
  text-decoration-line: underline;
`;

export const CustomView = styled(View)`
  display: flex;
  justify-content: space-between;
`;
