import styled from 'styled-components';
import View from '../View/View';
import Text from '../Text';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';

export const PolicyWrapper = styled(View)`
  border: 1px ${ThemePrimaryColor} solid;
  max-width: 1000px;
  margin: auto;
`;

export const ContentWrapper = styled(View)`
  margin: 24px 48px;
  line-height: 1.5;
`;

export const Header = styled(View)`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const Para = styled(View)`
  margin-top: 12px;
`;

export const ParaHeader = styled(Text)`
  font-weight: bold;
  font-size: 22px;
`;
