import View from '../View/View';
import Text from '../Text/Text';
import React from 'react';
import {
  FooterWrapper,
  LeftSection,
  RightSection,
  SubText,
  Title,
  TitleView,
  BlackLink,
  CustomView,
  CenterSection,
  CenterView,
} from './Footer.styles';
import {Visible} from '../Responsive/Visible';
import {Breakpoint} from '../Responsive/Breakpoint';
import AppLink from '../AppLink/AppLink';

export const getRefLink = (): string =>
  'https://pubs.rsna.org/doi/10.1148/radiol.2019182646';

export const getRefText = (): string =>
  'Silverman SG, Pedrosa I, Ellis JH, et al. Bosniak Classification of Cystic Renal Masses, Version 2019: An Update Proposal and Needs Assessment. Radiology 2019; 292:475-488';

const getMailTo = () => (
  <BlackLink
    link="mailto:satheeshkrishna.jeyaraj@utoronto.ca"
    text="satheeshkrishna.jeyaraj@utoronto.ca"
  />
);

const Footer = () => {
  return (
    <FooterWrapper>
      <Visible sizes={[Breakpoint.lg]}>
        <LeftSection>
          <TitleView>
            <Title>Reference:</Title>
          </TitleView>
          <SubText>
            <BlackLink link={getRefLink()} text={getRefText()} />
          </SubText>
        </LeftSection>
        <CenterSection>
          <AppLink />
          <CenterView>
            <Text>Try out Bosniak Calculator app</Text>
          </CenterView>
        </CenterSection>
        <RightSection>
          <TitleView>
            <Title>For feedback, contact:</Title>
          </TitleView>
          <View>
            <SubText>Dr. Satheesh Krishna</SubText>
            <SubText>
              {'\n'}({getMailTo()})
            </SubText>
          </View>
        </RightSection>
      </Visible>
      <Visible sizes={[Breakpoint.sm]}>
        <LeftSection>
          <CustomView>
            <TitleView>
              <Title>For feedback, contact:</Title>
            </TitleView>
            <AppLink />
          </CustomView>
          <View>
            <SubText>Dr. Satheesh Krishna</SubText>
            <SubText>
              {'\n'}({getMailTo()})
            </SubText>
          </View>
        </LeftSection>
      </Visible>
    </FooterWrapper>
  );
};

export default Footer;
