import * as React from 'react';
import styled from 'styled-components';
import View from '../View';
import PlayStoreLogo from '../../images/play_store.png';
import AppStoreLogo from '../../images/app_store.png';

const Wrapper = styled(View)`
  @media only screen and (min-width: 768px) {
    text-align: center;
  }
`;

const Image = styled.img`
  margin-right: 15px;
  height: 30px;
  width: 30px;

  :hover {
    cursor: pointer;
  }
`;

const AppLink = () => {
  return (
    <Wrapper>
      <Image
        src={PlayStoreLogo}
        alt="Play Store"
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=com.bosniakcalculator',
          )
        }
      />
      <Image
        src={AppStoreLogo}
        alt="App Store"
        onClick={() =>
          window.open('https://apps.apple.com/us/app/id1529618418')
        }
      />
    </Wrapper>
  );
};

export default AppLink;
