import React from 'react';
import View from '../View';
import styled from 'styled-components';
import Text from '../Text';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';

const HeaderText = styled(Text)`
  font-size: 28px;
  font-weight: bold;
`;

const Subtitle = styled(View)`
  margin-top: 10px;
`;

const Header = styled(View)`
  padding: 25px 0;
  text-align: center;
  background-color: ${ThemePrimaryColor};
`;

const AppBar = ({title, subtitle}: {title?: string; subtitle?: string}) => {
  return (
    <Header>
      <HeaderText>{title}</HeaderText>
      <Subtitle>
        <Text>{subtitle}</Text>
      </Subtitle>
    </Header>
  );
};

export default AppBar;
