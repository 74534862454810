import styled from 'styled-components';
import View from '../View/View';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';
import Text from '../Text';
import Touchable from '../Touchable/Touchable';

export const Card = styled(View)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const QuestionText = styled(Text)<{isCurrentQuestion: boolean}>`
  font-weight: bold;
  line-height: 1.5;
  ${({isCurrentQuestion}) => isCurrentQuestion && 'font-size: 18px;'};
`;

export const QuestionDescription = styled(View)`
  white-space: pre-wrap;
  flex: 7;
  padding: 25px;
`;

export const DescriptionText = styled(Text)<{isCurrentQuestion: boolean}>`
  font-style: italic;
`;

export const Selection = styled(View)`
  border-radius: 0 0 10px 10px;
  background-color: ${ThemePrimaryColor};
  flex: 3;
  font-weight: bold;
  padding: 15px 0 15px 25px;
  @media only screen and (min-width: 600px) {
    padding: 25px;
    border-radius: 0 10px 10px 0;
  }
  display: flex;
`;

export const SelectedOptionValue = styled(Text)``;

export const EditIconWrapper = styled(Touchable)`
  flex: 1;
  margin-right: 5px;
  cursor: pointer;
`;

export * from './Question.styles.shared';
