import React from 'react';
import {
  ContentWrapper,
  Header,
  Para,
  ParaHeader,
  PolicyWrapper,
} from './PrivacyPolicy.styles';
import Text from '../Text';

const PrivacyPolicy = () => (
  <PolicyWrapper>
    <ContentWrapper>
      <Header>
        <Text>Privacy Policy</Text>
      </Header>
      <Para>
        <Text>
          Deepa Sajani Jeyaraj built the Bosniak Calculator app as a Free app.
          This SERVICE is provided by Deepa Sajani Jeyaraj at no cost and is
          intended for use as is.
        </Text>
      </Para>
      <Para>
        <Text>
          This page is used to inform visitors regarding my policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use my Service.
        </Text>
      </Para>
      <Para>
        <Text>
          If you choose to use my Service, I assure you that no personal
          information will be collected through the app.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Information Collection and Use</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          No personally identifiable information is collected from the app. The
          information that you enter in the app will be retained on your device
          and is not collected by me in any way.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Service Providers</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          I want to inform users of this Service that this app does not
          integrate with any third party services and does not employ any
          third-party companies/individuals.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Security</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          This app does not collect any of your personal data nor does it use
          any third party services. I value your trust in us and I can assure
          the app's absolute security.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Links to Other Sites</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by me. Therefore, I strongly advise
          you to review the Privacy Policy of these websites. I have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Children's Privacy</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          These Services do not address anyone under the age of 18. This app is
          specifically to improve the productivity of doctors, especially
          radiologists.
        </Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Changes to This Privacy Policy</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          I may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. I will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </Text>
      </Para>
      <Para>
        <Text>This policy is effective as of 2020-08-27</Text>
      </Para>
      <Para>
        <Text>
          <ParaHeader>Contact Us</ParaHeader>
        </Text>
      </Para>
      <Para>
        <Text>
          If you have any questions or suggestions about my Privacy Policy, do
          not hesitate to contact me at deepasajani.jeyaraj@gmail.com.
        </Text>
      </Para>
    </ContentWrapper>
  </PolicyWrapper>
);

export default PrivacyPolicy;
