import styled from 'styled-components';
import View from '../View/View';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';

export const Wrapper = styled(View)`
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
`;

export const Card = styled(View)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled(View)`
  padding-top: 10px;
  border-radius: 0 100px;
  flex: 2;
  background-color: ${ThemePrimaryColor};
`;

export * from './Answer.styles.shared';
