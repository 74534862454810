import React, {useEffect} from 'react';
import {Answer as AnswerType} from './../../types/answers';
import {
  Wrapper,
  ResultWrapper,
  Card,
  ImageWrapper,
  Result,
  Classification,
  Description,
  DescriptionText,
} from './Answer.styles';
import {Visible} from '../Responsive/Visible';
import {Breakpoint} from '../Responsive/Breakpoint';
import RestartCalculator from '../RetakeTest/RetakeTest';
import {logTrackingEvent} from '../../tracking/Tracker';

type AnswerProps = {
  data: AnswerType;
  refresh: () => void;
};

export const Answer = ({data, refresh}: AnswerProps) => {
  useEffect(() => {
    logTrackingEvent('answer__shown', data);
  }, []);
  return (
    <Wrapper>
      <Card>
        <ResultWrapper>
          <ImageWrapper />
          <Result>
            <Classification>{data.answer}</Classification>
            {data.description && (
              <Description>
                <DescriptionText>{data.description}</DescriptionText>
              </Description>
            )}
          </Result>
        </ResultWrapper>
        <Visible sizes={[Breakpoint.lg]}>
          <RestartCalculator refresh={refresh} />
        </Visible>
      </Card>
      <Visible sizes={[Breakpoint.sm]}>
        <RestartCalculator refresh={refresh} />
      </Visible>
    </Wrapper>
  );
};
