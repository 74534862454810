import React from 'react';
import Text from '../Text';
import {Option as OptionType} from '../../types/questions';
import {OptionView, OptionsWrapper} from './Options.styles';

type OptionProps = {
  title: string;
  value: number;
  onSelectOption: (option: number) => void;
};

const Option = ({title, value, onSelectOption}: OptionProps) => {
  return (
    <OptionView onClick={() => onSelectOption(value)}>
      <Text>{title}</Text>
    </OptionView>
  );
};

type OptionsProps = {
  options: OptionType[];
  onSelectOption: (option: number) => void;
};

const Options = ({options, onSelectOption}: OptionsProps) => {
  return (
    <OptionsWrapper>
      {options.map((option: OptionType) => (
        <Option
          key={option.id}
          title={option.description}
          value={option.id}
          onSelectOption={onSelectOption}
        />
      ))}
    </OptionsWrapper>
  );
};

export default Options;
