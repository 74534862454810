import React, {ReactNode} from 'react';
import View from '../View/View';
import {ScrollView as RNScrollView} from 'react-native';
import styled from 'styled-components';

export type ScrollViewProps = {
  children: ReactNode;
  refs?: (rn: RNScrollView) => void;
};

const ScrollWrapper = styled(View)`
  padding: 24px;
`;

const ScrollView = ({children}: ScrollViewProps) => {
  return <ScrollWrapper>{children}</ScrollWrapper>;
};

export default ScrollView;
