import React, {ReactNode} from 'react';
import {Consumer} from './ResponsiveContext';
import {Breakpoint} from './Breakpoint';

interface VisibleProps {
  sizes: Breakpoint[];
  children: ReactNode;
}

const Visible = ({sizes, children}: VisibleProps) => (
  <Consumer>{(size) => sizes.includes(size) && children}</Consumer>
);

export {Visible};
