import React from 'react';
import {ViewProps} from './View.props';

const View = React.forwardRef(
  (
    {children, onRendered, ...rest}: ViewProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div ref={ref} {...rest}>
        {children && children}
      </div>
    );
  },
);

export default View;
