import React from 'react';
import Text from '../Text';
import RefreshIcon from '../../icons/RefreshIcon';
import {
  RetakeTest,
  RetakeTestIcon,
  Wrapper,
  RetakeTestTouchable,
} from './RetakeTest.styles';

const RestartCalculator = ({refresh}: {refresh: () => void}) => (
  <RetakeTestTouchable onClick={refresh}>
    <Wrapper>
      <RetakeTest>
        <Text>Restart Calculator</Text>
      </RetakeTest>
      <RetakeTestIcon>
        <RefreshIcon />
      </RetakeTestIcon>
    </Wrapper>
  </RetakeTestTouchable>
);

export default RestartCalculator;
