import styled from 'styled-components';
import View from '../View/View';
import Text from '../Text/Text';

export const ReferenceWrapper = styled(View)`
  margin-top: 25px;
`;

export const ReferenceText = styled(Text)`
  font-family: 'Montserrat-Italic';
  font-size: 14px;
`;
