import styled from 'styled-components';
import View from './components/View';
import {ThemePrimaryColor} from './components/ThemeProvider/ThemeProvider.shared';

export const AppWrapper = styled(View)`
  border: 1px ${ThemePrimaryColor} solid;
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-flow: column;
`;

export * from './App.styles.shared';
