import styled from 'styled-components';
import Text from '../Text/Text';
import View from '../View/View';
import {ThemePrimaryColor} from '../ThemeProvider/ThemeProvider.shared';

export const FooterWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  box-shadow: 0px -4px 3px ${ThemePrimaryColor};
  opacity: 0.9;
`;

export const SubText = styled(Text)`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TitleView = styled(View)`
  margin-bottom: 5px;
  display: inline-block;
`;

export const CenterView = styled(View)`
  text-align: center;
  margin-top: 5px;
`;

export * from './Footer.styles.shared';
