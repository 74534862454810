import styled from 'styled-components';
import View from '../View/View';
import {
  ShadowColor,
  ThemePrimaryColor,
} from '../ThemeProvider/ThemeProvider.shared';
import Touchable from '../Touchable/Touchable';

export const OptionsWrapper = styled(View)`
  margin-top: 25px;
`;

export const OptionView = styled(Touchable)`
  border-radius: 10px;
  border: 1px ${ShadowColor} solid;
  margin: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 0px ${ShadowColor}, inset -1px -2px 3px ${ShadowColor};
  @media only screen and (min-width: 600px) {
    :hover {
      background-color: ${ThemePrimaryColor};
    }
  }
`;
