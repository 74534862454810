import styled from 'styled-components';
import View from '../View/View';

export const QuestionView = styled(View)<{isCurrentQuestion: boolean}>`
  ${({isCurrentQuestion}) => isCurrentQuestion && 'text-align: center;'};
`;

export const DescriptionView = styled(View)<{isCurrentQuestion: boolean}>`
  margin-top: 10px;
  ${({isCurrentQuestion}) => isCurrentQuestion && 'text-align: center;'};
`;

export const SelectedOption = styled(View)`
  flex: 9;
`;
