import React, {createRef, RefObject, useEffect, useState} from 'react';
import data from './data.json';
import {Data} from './types/data';
import {Option} from './types/questions';
import {Question} from './components/Question/Question';
import {Answer} from './components/Answer/Answer';
import {AppWrapper, ContentWrapper, Header, HeaderWrapper} from './App.styles';
import {ThemeProvider} from './components/ThemeProvider/ThemeProvider';
import Footer from './components/Footer/Footer';
import {ResponsiveProvider} from './components/Responsive/ResponsiveProvider';
import Reference from './components/Reference/Reference';
import {ScrollView} from 'react-native';
import {ScrollViewContainer} from './components/ScrollView';
import View from './components/View';
import {AppTitle} from './AppInfo';
import {initializeTracker, logTrackingEvent} from './tracking/Tracker';

type selectedOptionsState = {
  [key: number]: number;
};

const App = () => {
  const initialQuestionOptionMap: selectedOptionsState = {};
  const {firstQuestion, questions, answers}: Data = data;
  const [selectedQuestionOptionMap, updateQuestionOptionMap] = useState(
    initialQuestionOptionMap,
  );
  const [currentQuestion, setCurrentQuestion] = useState<number | undefined>(
    firstQuestion,
  );
  const [finalAnswerNumber, setFinalAnswerNumber] = useState<
    number | undefined
  >();
  const getNextQuestion = (option: Option): number | undefined => {
    if (option.nextQuestion || option.answer) {
      return option.nextQuestion;
    }
    return (
      option.condition &&
      option.condition.find(
        (condition) =>
          selectedQuestionOptionMap[condition.question] === condition.option,
      )?.nextQuestion
    );
  };
  const selectOption = (questionNumber: number, option: Option) => {
    setCurrentQuestion(getNextQuestion(option));
    updateQuestionOptionMap({
      ...selectedQuestionOptionMap,
      [questionNumber]: option.id,
    });
    setFinalAnswerNumber(option.answer);
  };
  const editSelectedOption = (questionNumber: number) =>
    setCurrentQuestion(questionNumber);
  const getQuestionData = (questionNumber: number) => questions[questionNumber];
  const getAnswerData = (answerNumber: number) => answers[answerNumber];
  const renderAnsweredQuestions = () => {
    if (Object.keys(selectedQuestionOptionMap).length === 0) {
      return <></>;
    }
    const result = [];
    let currentQuestionNumber: number | undefined = firstQuestion,
      selectedOption: number;
    while (
      currentQuestionNumber &&
      (currentQuestion ? currentQuestionNumber < currentQuestion : true) &&
      (selectedOption = selectedQuestionOptionMap[currentQuestionNumber])
    ) {
      const questionData = getQuestionData(currentQuestionNumber);
      result.push(
        <Question
          key={questionData.id}
          data={questionData}
          selectedOption={selectedOption}
          editSelectedOption={editSelectedOption}
        />,
      );
      const selectedOptionObj = questionData.options.find(
        (o: Option) => o.id === selectedOption,
      );
      currentQuestionNumber =
        selectedOptionObj && getNextQuestion(selectedOptionObj);
    }
    return result;
  };

  const refreshCalculator = () => {
    updateQuestionOptionMap(initialQuestionOptionMap);
    setFinalAnswerNumber(undefined);
    setCurrentQuestion(firstQuestion);
    logTrackingEvent('refresh_calculator__click');
  };

  const resultRef = createRef<HTMLInputElement>();
  let scrollViewRef: ScrollView | null;
  const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
    if (ref && ref.current && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({block: 'end', behavior: 'smooth'});
    }
  };

  useEffect(() => {
    scrollToRef(resultRef);
  });
  useEffect(() => {
    initializeTracker();
    logTrackingEvent('home__view', {
      url: window ? window.location : 'apps',
    });
  }, []);

  return (
    <ThemeProvider>
      <ResponsiveProvider>
        <AppWrapper style={{height: window.innerHeight}}>
          <HeaderWrapper>
            <Header title={AppTitle} subtitle={'Version 2019 - Calculator'} />
          </HeaderWrapper>

          <ContentWrapper>
            <ScrollViewContainer refs={(ref) => (scrollViewRef = ref)}>
              {renderAnsweredQuestions()}
              {currentQuestion && (
                <View
                  onRendered={(yPosition) => {
                    scrollViewRef &&
                      scrollViewRef.scrollTo({
                        y: yPosition,
                        animated: true,
                      });
                  }}>
                  <Question
                    data={getQuestionData(currentQuestion)}
                    selectOption={selectOption}
                  />
                </View>
              )}
              {finalAnswerNumber && !currentQuestion && (
                <>
                  <Answer
                    data={getAnswerData(finalAnswerNumber)}
                    refresh={refreshCalculator}
                  />
                  <Reference />
                  <View
                    ref={resultRef}
                    onRendered={(yPosition) => {
                      scrollViewRef &&
                        scrollViewRef.scrollTo({
                          y: yPosition,
                          animated: true,
                        });
                    }}
                  />
                </>
              )}
            </ScrollViewContainer>
          </ContentWrapper>

          <Footer />
        </AppWrapper>
      </ResponsiveProvider>
    </ThemeProvider>
  );
};

export default App;
