import {Breakpoint} from '../Responsive/Breakpoint';
import {ReferenceWrapper, ReferenceText} from './Reference.styles';
import {getRefLink, getRefText} from '../Footer/Footer';
import {Visible} from '../Responsive/Visible';
import React from 'react';
import {BlackLink} from '../Footer/Footer.styles';

const Reference = () => {
  const referenceText = 'Reference: ' + getRefText();
  return (
    <Visible sizes={[Breakpoint.sm]}>
      <ReferenceWrapper>
        <ReferenceText>
          <BlackLink link={getRefLink()} text={referenceText} />
        </ReferenceText>
      </ReferenceWrapper>
    </Visible>
  );
};

export default Reference;
