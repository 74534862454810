import styled from 'styled-components';
import AppBar from './components/AppBar';
import View from './components/View/View';

export const Header = styled(AppBar)``;

export const HeaderWrapper = styled(View)``;

export const ContentWrapper = styled(View)`
  flex: 2;
  overflow: scroll;
`;
