import React, {createRef, RefObject, useEffect} from 'react';
import {Option, Question as QuestionType} from './../../types/questions';
import EditIcon from '../../icons/EditIcon';
import {
  Card,
  DescriptionView,
  DescriptionText,
  QuestionView,
  QuestionText,
  QuestionDescription,
  Selection,
  SelectedOption,
  SelectedOptionValue,
  EditIconWrapper,
} from './Question.styles';
import Options from '../Options/Options';

type QuestionProps = {
  data: QuestionType;
  selectedOption?: number;
  selectOption?: (questionNumber: number, option: Option) => void;
  editSelectedOption?: (questionNumber: number) => void;
};

export const Question = ({
  data,
  selectedOption,
  selectOption,
  editSelectedOption,
}: QuestionProps) => {
  // Scroll to question view
  const cardRef = createRef<HTMLInputElement>();
  const scrollToRef = (ref: RefObject<HTMLInputElement>) => {
    if (ref && ref.current && ref.current.scrollIntoView && isCurrentQuestion) {
      ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  };
  useEffect(() => {
    scrollToRef(cardRef);
  });

  const getOption = (optionNumber: number) =>
    data.options.find((o: Option) => o.id === optionNumber);
  const handleOptionChange = (optionNumber: number) => {
    const currentSelectedOption = getOption(optionNumber);
    currentSelectedOption &&
      selectOption &&
      selectOption(data.id, currentSelectedOption);
  };
  const isCurrentQuestion = !selectedOption;
  const changeSelectedOption = () =>
    editSelectedOption && editSelectedOption(data.id);
  return (
    <Card ref={cardRef}>
      <QuestionDescription>
        <QuestionView isCurrentQuestion={isCurrentQuestion}>
          <QuestionText isCurrentQuestion={isCurrentQuestion}>
            {data.question}
          </QuestionText>
        </QuestionView>
        {data.description && (
          <DescriptionView isCurrentQuestion={isCurrentQuestion}>
            <DescriptionText isCurrentQuestion={isCurrentQuestion}>
              {data.description}
            </DescriptionText>
          </DescriptionView>
        )}
        {isCurrentQuestion && (
          <Options options={data.options} onSelectOption={handleOptionChange} />
        )}
      </QuestionDescription>
      {selectedOption && (
        <Selection>
          <SelectedOption>
            <SelectedOptionValue>
              {getOption(selectedOption)?.description}
            </SelectedOptionValue>
          </SelectedOption>
          <EditIconWrapper onClick={changeSelectedOption}>
            <EditIcon />
          </EditIconWrapper>
        </Selection>
      )}
    </Card>
  );
};
