import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

const RoutedApp = () => {
  return (
    <Router>
      <Switch>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  );
};

ReactDOM.render(<RoutedApp />, document.getElementById('root'));
