import * as firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseWebConfig = {
  apiKey: 'AIzaSyAV6yjfLgLQd_9IkGzTx9J9cxXjGGIlu4g',
  authDomain: 'bosniakcalculator.firebaseapp.com',
  databaseURL: 'https://bosniakcalculator.firebaseio.com',
  projectId: 'bosniakcalculator',
  storageBucket: 'bosniakcalculator.appspot.com',
  messagingSenderId: '974661580261',
  appId: '1:974661580261:web:5236669a4f80b42e7d2b0c',
  measurementId: 'G-BCXNNC822R',
};

export const initializeTracker = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseWebConfig);
    firebase.analytics();
  }
};

export const logTrackingEvent = (
  eventName: string,
  params?: {[key: string]: any},
) => {
  firebase.analytics().logEvent(eventName, params);
};
